import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js';
import '@/assets/scss/style.scss';
import '@/assets/css/materialdesignicons.min.css'
import '@/assets/css/animate.css'
import '@/assets/css/animations-delay.css'

createApp(App).use(router).mount('#app')
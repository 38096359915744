import { createRouter, createWebHistory } from 'vue-router'
import IndexStartup from '@/pages/index/index-startup.vue'

const routes = [
  {
    path: '/',
    component: IndexStartup
  },
  {
    path: '/:pathMatch(.*)',
    component: IndexStartup
  }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <!-- Footer Start -->
  <footer class="footer bg-footer">
    <div class="py-5 footer-border-bottom" :class="top ? 'd-none' : ''">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="section-title">
              <div class="d-flex align-items-center">
                <i
                  class="uil uil-envelope-star display-4 text-white title-dark"></i>
                <div class="flex-1 ms-md-4 ms-3">
                  <h4 class="fw-bold text-light title-dark mb-1">
                    Besoin de plus d'informations ?
                  </h4>
                  <p class="text-white-50 mb-0">
                    Contactez nous, et nous nous ferons un plaisir de vous
                    répondre !
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-4 mt-sm-0">
            <div class="text-md-end ms-5 ms-sm-0">
              <a
                href="https://calendly.com/brimel-webdev/30min"
                target="_blank"
                class="btn btn-primary"
                >Prendre contact</a
              >
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div>
    <!--end div-->

    <!--end container-->

    <div class="footer-py-30 footer-bar bg-footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-4">
            <div class="text-sm-start text-center">
              <router-link href="#">
                <img :src="logo" height="26" alt="" />
              </router-link>
            </div>
          </div>
          <!--end col-->

          <div class="col-sm-8 mt-4 mt-sm-0">
            <div class="text-sm-end text-center">
              <p class="mb-0 text-muted">{{ year }} © Visionary</p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div>
  </footer>
  <!--end footer-->
  <!-- End -->
</template>

<script setup>
import logo from "@/assets/images/logo-light.png";
import { ref, defineProps } from "vue";

let year = ref(new Date().getFullYear());
defineProps({
  top: Boolean,
});
</script>

<template>
  <div>
    <!-- Hero Start -->
    <section class="home-slider position-relative">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="3000">
            <div
              class="bg-home d-flex align-items-center"
              style="background-position: top"
              :style="{
                'background-image': `url(https://images.unsplash.com/photo-1707200148146-82779c4b239b?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
              }">
              <div class="bg-overlay"></div>
              <div class="container">
                <div class="row justify-content-center mt-5">
                  <div class="col-lg-12 text-center">
                    <div class="title-heading mt-4">
                      <h4 class="display-3 fw-bold text-white title-dark mb-3">
                        Bienvenue chez Visionary
                      </h4>
                      <p class="text-white-50 mx-auto para-desc">
                        Ou votre talent rencontre notre expertise
                      </p>

                      <div class="mt-4 pt-2">
                        <a
                          href="https://calendly.com/brimel-webdev/30min"
                          class="btn btn-lg btn-primary"
                          target="_blank"
                          >Prendre contact</a
                        >
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
              <!--end container-->
            </div>
            <!--end slide-->
          </div>
        </div>
      </div>
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- FEATURES START -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="features-absolute">
              <div class="row g-4">
                <div
                  v-for="(item, index) in featureData"
                  :key="index"
                  class="col-md-6">
                  <div
                    class="features feature-primary rounded px-4 py-5 bg-white shadow border-0 d-flex align-items-center">
                    <div class="fea-icon rounded-circle bg-light text-dark">
                      <i :class="item.icon"></i>
                    </div>

                    <div class="content flex-1 ms-3">
                      <router-link
                        to="/page-single-service"
                        class="title h5 text-dark"
                        >{{ item.title }}</router-link
                      >
                      <p class="text-muted mt-2 mb-0">{{ item.desc }}</p>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6">
            <img :src="aboutImg" class="img-fluid rounded shadow" alt="" />
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="section-title ms-lg-5">
              <h4 class="title fw-semibold mb-3">Qui sommes-nous ?</h4>
              <p class="text-muted">
                Que vous soyez une entreprise locale à la recherche de ses
                premiers clients, une agence en besoin d'une identité visuelle
                professionnelle ou une entreprise en expansion, nous nous
                spécialisons dans la conception de sites internet sur mesure, de
                stratégies de communication et d'identités visuelles.
              </p>
              <ul class="list-unstyled text-muted mb-0">
                <li
                  v-for="(item, index) in aboutData"
                  :key="index"
                  class="mb-0">
                  <span class="text-dark h5 me-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >{{ item }}
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- FEATURES End -->

    <!-- Start -->

    <!--end section-->
    <!-- End -->

    <!-- Start Pricing -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="section-title text-center mb-4 pb-2">
              <h4 class="title fw-semibold mb-4">Nos offres</h4>
              <p class="para-desc text-muted mx-auto mb-0">
                Découvrez nos offres, nos services, prestations et tarifs
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <PricingOne />
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End Pricing -->

    <!-- Start -->
    <section class="section bg-light overflow-hidden">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="me-lg-5">
              <div class="row justify-content-center">
                <div class="col-12">
                  <div
                    class="section-title text-center text-md-start mb-4 pb-2">
                    <h4 class="title fw-semibold mb-4">
                      Comment fonctionnons nous ?
                    </h4>
                    <p class="para-desc text-muted mx-auto mb-0">
                      De la définition de vos objectifs à la livraison de votre
                      projet, nous vous suivons et vous tenons informés
                    </p>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->

              <div class="row g-4">
                <div
                  v-for="(item, index) in workData"
                  :key="index"
                  class="col-md-12">
                  <div class="features feature-primary rounded border-0 d-flex">
                    <div
                      class="fea-icon rounded-circle text-white title-dark shadow avatar avatar-md-sm"
                      style="height: 40px; width: 40px">
                      {{ item.id }}
                    </div>

                    <div class="content flex-1 ms-3">
                      <router-link
                        to="/page-single-service"
                        class="title h5 text-dark"
                        >{{ item.title }}</router-link
                      >
                      <p class="text-muted mt-2 mb-0">{{ item.desc }}</p>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="img-fluid-responsive position-relative">
              <img :src="lapy" class="mx-auto d-block" alt="" />
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End -->

    <FooterOne />

    <ScrollToTop />
  </div>
</template>

<script setup>
import { ref } from "vue";
// import ProjectOne from "@/components/project/project-one.vue";
import PricingOne from "@/components/pricing/pricing-one.vue";

import aboutImg from "@/assets/images/about.jpg";
import lapy from "@/assets/images/lapy.png";
import ScrollToTop from "@/components/scrollToTop.vue";
import FooterOne from "@/components/footer/footer-one.vue";

let featureData = ref([
  {
    icon: "uil uil-airplay",
    title: "Optimisez votre site web",
    desc: "Rendez votre site plus accessible, plus visible, et générez plus de traffic",
  },
  {
    icon: "uil uil-eye",
    title: "Créez votre site web",
    desc: "Mettez votre activité en valeur avec un site qui vous ressemble",
  },
  {
    icon: "uil uil-tachometer-fast-alt",
    title: "Développez votre SaaS",
    desc: "De l'application simple à la SaaS, contactez nous pour développer votre nouvelle activité",
  },
  {
    icon: "uil uil-palette",
    title: "Créez votre identité visuelle",
    desc: "Besoin d'un logo, d'une charte graphique, d'une bannière ? C'est par ici !",
  },
]);

let aboutData = ref([
  "Sites et applications web sur mesure",
  "Optimisation SEO",
  "Identités visuelles modernes",
]);

let workData = ref([
  {
    id: 1,
    title: "Conceptualisation",
    desc: "Nous définissons d'abord vos objectifs, vos besoins, puis nous vous proposons un devis. Et ce devis, il est gratuit !",
  },
  {
    id: 2,
    title: "Développement",
    desc: "Une fois votre devis signé, notre contrat signé et votre accompte versé, nous démarrons le développement de votre projet",
  },
  {
    id: 3,
    title: "Démonstration",
    desc: "Dans un délai d'en général une semaine, nous vous proposons une démonstrasion en direct de votre projet.",
  },
  {
    id: 4,
    title: "Livraison",
    desc: "Enfin, une fois que votre projet est prêt, nous le mettons en ligne !",
  },
]);
</script>

<template>
  <a
    href=""
    @click="scrollToTop($event)"
    id="back-to-top"
    :class="scroll ? 'd-block' : 'd-none'"
    class="back-to-top rounded-pill"
    ><i class="mdi mdi-arrow-up"></i
  ></a>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";

const scroll = ref(false);

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

function scrollToTop(e) {
  e.preventDefault();

  window.scrollTo(0, 0);
}

function handleScroll() {
  if (window.scrollY > 400) {
    scroll.value = true;
  } else {
    scroll.value = false;
  }
}
</script>
